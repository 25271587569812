import React from 'react'
import styled from "styled-components"

const BoxBorderless = styled.div`
 


  color: #000000;
  transition-duration: 0.3s;
  text-align: center;

  margin: 18px 10px 12px 0px;
}


`

export default ({children, ...opts}) => (
  <BoxBorderless {...opts}>
    {children}
  </BoxBorderless>
)
