import React from 'react'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import Scroll from './Scroll';
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import styled,{ keyframes } from 'styled-components';
import { bounce, pulse } from 'react-animations';
 

const bounceAnimation = keyframes`${bounce}`;
const BouncyDiv = styled.div`
  animation: 5s ${bounceAnimation};
`;

const zoomInAnimation = keyframes`${pulse}`;

const HeroContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  height: 750px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: 5s ${zoomInAnimation};
`

const DownImage = styled(GatsbyImage)`
width: 3rem;
margin: auto;
top: 165px;

  `

const Hero = props => {
  const flecha = getImage(props.down);
  const { siteCover, imageLogo } = useSiteMetadata()
  const { fluid } = useSiteImages(siteCover)
  const logo = useSiteImages(imageLogo)
  const heroImg = props.heroImg || fluid.src
  const classStyle = props.classStyle

  return (
    <div>
      <HeroContainer style={{ backgroundImage: `url("${heroImg}")` }} className={classStyle}>
        <div className="container d-flex align-items-center">
          <div className="mx-auto margin-hero">
          <img src={logo.fluid.src } className="imglogo"/>
            <Scroll type="id" element="about">
              <a href="#about" >
                <BouncyDiv >
                  <DownImage image={flecha}/>
                </BouncyDiv>
              </a>
            </Scroll>
          </div>
        </div>
      </HeroContainer>

    </div>

  )
}

export default Hero