import React, { Component } from 'react';
import { Col, Button, Form, FormGroup, Input } from 'reactstrap';
import axios from "axios";
import "../assets/stylesheets/contact.scss"

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            'message': '',
            'name': '',
            'email': '',
            'subject': '',
            validate: {
                emailState: '',
                nameState: '',
                messageState:'',
                subjectState: ''
            },
            serverState:{
                submitting: false,
                status: null
            }
        }
      
        this.handleChange = this.handleChange.bind(this);
    
    }
   
    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateText() {
        const { validate } = this.state
        validate.nameState =  this.state.name.trim() !== '' ? 'has-success' : 'has-danger';
        validate.messageState =  this.state.message.trim() !== '' ? 'has-success' : 'has-danger';
        validate.subjectState =  this.state.subject.trim() !== '' ? 'has-success' : 'has-danger';

        this.setState({ validate })
    }

    handleChange = async (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value,
        });
    }
        
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
          [name]: value,
        })
      }

    handleServerResponse(ok, msg, form) {
        const { serverState } = this.state
        serverState.submitting = false;
        serverState.status= { ok, msg };
        this.setState({ serverState });
     
        if (ok) {
            form.reset();
            this.setState({
                message: '',
                name: '',
                email: '',
                subject: ''
              });
        }
      }

    submitForm(e) {
        e.preventDefault();
        this.validateEmail(e);
        this.validateText();
        const form = e.target;

        this.setState({
            serverState:{
            submitting: true
        }});

        var config = { 
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
         }

         console.log(this.state.validate);

        if(this.state.validate.nameState === 'has-success' && this.state.validate.subjectState === 'has-success' && this.state.validate.messageState === 'has-success'){
            axios.post("http://192.168.0.24:3000/sendEmail", {data: this.state}, config)
            this.handleServerResponse(true, 'El formulario se envio con exito!', form);
        }else{
            this.handleServerResponse(false, 'Fallo el envio del formulario, intente nuevamente en unos minutos', form);
        }

        

        // axios({
        //   method: "POST",
        // //   url: "http://64.215.200.21:8080/portalServer/portalPuntano/contact",
        //   url: "http://localhost:3000/sendEmail",
        //   data: this.state,
        //   config     
        // })
        //     .then(r => {
        //     this.handleServerResponse(true, r.data.response, form);
        //   })
        //     .catch(r => {
        //     this.handleServerResponse(false, 'Fallo el envio del formulario, intente nuevamente en unos minutos', form);
        //   });

    }

    render() {
        return (
            <Form onSubmit={(e) => this.submitForm(e)} className="contact_form">
                <FormGroup row>
                     <Col sm={5} md={6}>
                        <Input type="text" name="name" id="contactName" value={this.state.name} onChange={this.handleInputChange} 
                        valid={this.state.name !== undefined && this.state.name.trim().length > 0 ? this.state.name : undefined} placeholder="Ingrese su nombre"
                        required/>
                    </Col>
                    <Col sm={5} md={6}>
                        <Input type="email" name="email" id="contactEmail" value={this.state.email} onChange={this.handleInputChange}
                           valid={this.state.email !== undefined && this.state.email.trim().length > 0 ? this.state.email : undefined } 
                           required placeholder="Ingrese un email"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={10} md={12}>
                        <Input type="text" name="subject" id="subject" value={this.state.subject} onChange={this.handleInputChange} 
                            valid={this.state.subject !== undefined && this.state.subject.trim().length > 0 ? this.state.subject : undefined} placeholder="Ingrese su asusto"
                            required/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col sm={10} md={12}>
                        <Input type="textarea" name="message" id="contactMessage" value={this.state.message} onChange={this.handleInputChange}  style={ {height: "150px"}}  
                           valid={this.state.message !== undefined && this.state.message.trim().length > 0 ? this.state.message : undefined} required placeholder="Escriba un mensaje"/>
                    </Col>
                </FormGroup>
                <Col sm={10} md={12}>
                <Button className="btn btn-primary center mx-auto" disabled={this.state.serverState.submitting}>ENVIAR</Button>
                { this.state.serverState.status && (
                <p className={!this.state.serverState.status.ok ? "errorMsg" : "mailOk"}>
                {this.state.serverState.status.msg}</p> 
                )}
            </Col>
            </Form>
        );
    }
}

export default ContactForm;