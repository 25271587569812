import React from 'react'
import { graphql } from 'gatsby'
import '../assets/stylesheets/index.scss'
import Layout from "../components/layout"
import { Row, Col } from 'reactstrap'
import Link from '../components/link'
import BoxBorderless from '../components/boxBorderless'
import styled from 'styled-components'
import { GatsbyImage } from "gatsby-plugin-image";
import ContactForm from '../components/contactForm'
import Hero from '../components/Hero'
import SocialLinks from '../components/SocialLinks';
import TopBtn from '../components/topBtn';
import { Button } from 'reactstrap';
import Scroll from '../components/Scroll';

export const listQuery = graphql`query ListQuery {
  site {
    siteMetadata {
      title
      description
    }
  }
  flecha: file(relativePath: {eq: "assets/images/onePage/Flechas.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH , placeholder: BLURRED)
    }
  }
  allMarkdownRemark {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          idServ
          type
          title
          order
          link
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FIXED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
}
`;

const LinksImage = styled(GatsbyImage)`
  max-width: 200px;
  max-height: 200px;
  display: block;
  `

const BoxWrapper = styled.div`
  height: 300px;
  transition-duration: 0.3s;
  background-color: #e6e6e6;
  text-align: center;
  color:black;
  border-radius: 34px;
  width: 300px;
  padding: 20px 40px 30px 40px;
  :hover {
    background-color: #00eb9e;
    text-decoration: none;
  }
  margin: 20px auto 20px auto;
`

let Services = ({ title, image, idServ }) => (
  <div className="col-lg col-sm-11 mx-auto">
    <Link to={"/services/#" + idServ}>
      <BoxBorderless>
        <LinksImage className="m-auto" image={image} imgStyle={{
          objectFit: "contain",
        }} />
        <h5 className="mt-3 text-white">{title}</h5>
      </BoxBorderless>
    </Link>
  </div>
)

let Jobs = ({ title, link, description }) => (
  <Col lg="4" md="4" sm="12" className="mx-auto">
    <Scroll type="id" element="contact"  offset={-100}>
      <a href="#contact">
        <BoxWrapper>
          <h5 className="mt-3 ">{title}</h5>
          <p className="mt-3 descriptionApp">{description}</p>
        </BoxWrapper>
      </a>
    </Scroll>
  </Col>

)

function sortList(list) {
  return list.sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order);
}


export default function IndexPage(props) {
  const contentList = props.data.allMarkdownRemark;
  const flecha = props.data.flecha.childImageSharp
  const servicesList = contentList.edges.filter(edge => edge.node.frontmatter.type && edge.node.frontmatter.type === "service");
  const jobList = contentList.edges.filter(edge => edge.node.frontmatter.type && edge.node.frontmatter.type === "job");
  sortList(servicesList);
  return (
    <Layout>
      <Hero down={flecha} />
      
      <section id="about" className="about-section text-center">
        <div className="container">
          <div className="row">
          <div className="col mx-auto">
            <h2 className="text-white mb-4">SOBRE NOSOTROS</h2>
              <div className="col mx-auto p-4">
                <p className="text-white">
                  Somos una empresa con experiencia y mentalidad ágil que desarrolla software de calidad, poque es lo que nos apasiona. Acompañamos con compromiso,
                  responsabilidad y honestidad la transformación digital de nuestros clientes, creando relaciones sustentables y resultados a corto plazo.
              </p>
              </div>
          </div>
          
          </div>
        </div>
      </section>

      <section id="services" className="services-section text-center">
        <div className="row">
          <div className="col col-11 mx-auto">
            <Link to={'/services'}>
              <h2 className="text-white mb-4">NUESTROS SERVICIOS</h2>
            </Link>
            <Row>
              {servicesList.map(({ node }, i) => (
                <Services title={node.frontmatter.title} image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                  idServ={node.frontmatter.idServ} key={i} />
              ))}</Row>
          </div>
        </div>
      </section>

      <section id="products" className="product-section text-center mt-5 ">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <h2 className="text-black mb-4">PRODUCTOS</h2>
              <p className="text-black">
                Somos especialistas en informatizacion de <b>Gobierno Electronico</b> desarrollando proyectos como Historia Clínica Digital, Sistema para Gestión Educativa, Sistema de Firma Digital y Portales Ciudadanos con
              amplia interoperabilidad. A su vez, nos enfocamos en <b>brindar soluciones digitales para la sociedad</b>, generando aplicaciones, sistemas de control de acceso, entre otros.</p>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="mx-auto text-center">
            <Link to={'/products'}>
              <Button color="primary">VER MAS</Button>
            </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="works" className="works-section text-center mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col mx-auto">
              <h2 className="text-white mb-4">PRINCIPALES TRABAJOS</h2>
              <p className="text-white">
                Somos especialistas en informatizacion de <b>Gobierno Electronico</b> desarrollando proyectos como Historia Clínica Digital, Sistema para Gestión Educativa, Sistema de Firma Digital y Portales Ciudadanos con
              amplia interoperabilidad. A su vez, nos enfocamos en <b>brindar soluciones digitales para la sociedad</b>, generando aplicaciones, sistemas de control de acceso, entre otros.</p>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="mx-auto text-center">
            <Link to={'/works'}>
              <Button color="primary">VER MAS</Button>
            </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="jobs" className="jobs-section  text-center mb-5 mt-5 pt-4 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 mx-auto">
              <h2 className="text-black mb-4">TRABAJÁ CON NOSOSTROS</h2>
              <Row>
                {jobList.map(({ node }, i) => (
                  <Jobs title={node.frontmatter.title} link={node.fields.slug} description={node.frontmatter.description} key={i}/>

                ))}
              </Row>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact-section text-center pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-11 mx-auto">
              <h2 className="text-white mb-4">CONTACTÁNOS</h2>
              <Row>
                <div className="col-lg-6 col-sm-12 mx-auto m-4">
                  <p className="text-white m-5">Somos una empresa con experiencia y mentalidad ágil que desarrolla software de calidad, poque es lo que nos apasiona.</p>
                  <p className="text-white m-4">Acompañamos con compromiso, responsabilidad y honestidad la transformación digital de nuestros clientes,
                    creando relaciones sustentables y resultados a corto plazo.</p>
                  {/* <div className="mt-5">
                    <SocialLinks />
                  </div> */}
                  
                </div>
                <div className="col-lg-6 col-sm-12 mx-auto">
                  <ContactForm />
                </div>
              </Row>
            </div>
          </div>
        </div>
      </section>
      <TopBtn showBelow={250} />
    </Layout>
  );
}